/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import '../App.css'
import TokenAbi from '../config/TokenAbi.json'
import PresaleAbi from '../config/PresaleAbi.json'
import "../styles/PresaleContainer.css";
import Input from "../components/Input.tsx";
// import Footer from "../container/Footer.jsx";
import { readContract, writeContract } from '@wagmi/core'
import ClipLoader from "react-spinners/ClipLoader";
import { waitForTransaction } from '@wagmi/core'
import eth from '../icons/eth.png';
import usdc from '../icons/usdc.png'
import usdt from '../icons/USDT.svg';
import R0AR from '../icons/R0AR-Token.png'
import R0ARHeader from '../icons/TokenLogo.png'
import Web3 from "web3";
import { useWeb3Modal } from "@web3modal/react";
import styles from "../styles/container/Container.module.scss";
import { toast } from "react-hot-toast";
const App = () => {
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  const [tokenAmount, setAmount] = useState(0);
  const [allowanceAmount, setAllowanceAmount] = useState(0);
  const [raisedAmount, setRaisedAmount] = useState(0);
  let [loading, setLoading] = useState(false);
  let [confirming, setConfirming] = useState(false);
  const PresaleAddress = "0x7247162864802af9adfd3be4f15999d4e0dd685f";
  const usdcAddress = "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48";
  const usdtAddress = "0xdac17f958d2ee523a2206206994597c13d831ec7";

  const [selectedToken, setSelectToken] = useState(0);
  const [selectedTokenName, setSelectedTokenName] = useState("ETH");
  const [outTokenAmount, setOutTokenAmount] = useState(0);
  let [accountBalance, setAccountBalance] = useState(0);
  const [firstConnect, setFirstConnect] = useState(false);
  const ETH_PROVIDER_URL = "https://ethereum.publicnode.com";
  let ETHweb3 = new Web3(new Web3.providers.HttpProvider(ETH_PROVIDER_URL));
  const [progress, setProgress] = useState('0%');
  const { open } = useWeb3Modal();
  const [presaleId, setPresaleId] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [tokenSoldAmount, setTokenSoldAmount] = useState(0);
  const [totalToknesToSell, setTotalTokensToSell] = useState(0);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [nextPrice, setNextPrice] = useState(0);
  const [presaleStatus, setPresaleStatus] = useState("null");
  const [claimable, setClaimable] = useState(false);
  const [claimableAmount, setClaimableAmount] = useState(0);
  
  const [totalRaisedAmount, setTotalRaisedAmount] = useState(0);
  const onConnect = async () => {
    await open();
  };

  const onConnectWallet = async () => {
    await open();
    setFirstConnect(true);
  };
  const { isLoading, pendingChainId, switchNetwork } = useSwitchNetwork()
  useEffect(() => {
    const switchChain = async () => {
      try {
        switchNetwork?.(1)
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true) {
      if (chain.id !== 1)
        switchChain();
    }
  }, [isConnected, chain?.id, switchNetwork])

  useEffect(() => {
    const reloadWindow = async () => {
      try {
        window.location.reload();
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true && firstConnect === true)
      reloadWindow();

  }, [isConnected, firstConnect])


  useEffect(() => {
    if (loading === true) {
      setTimeout(function () {
        setLoading(false)
      }, 3000)
    }
  }, [loading])

  const onTokenAllowance = async () => {
    try {
      setConfirming(true);
      let approve;
      if (selectedToken === 1) {
        approve = await writeContract({ address: usdcAddress, abi: TokenAbi, functionName: 'approve', args: [PresaleAddress, ETHweb3.utils.toBN(String(tokenAmount * (10 ** 6)))] })
      } else if (selectedToken === 2) {
        if (Number(allowanceAmount) > 0) {
          approve = await writeContract({ address: usdtAddress, abi: TokenAbi, functionName: 'approve', args: [PresaleAddress, '0'] })
          toast.success('0 USDT approved. Please approve again');
        } else {
          approve = await writeContract({ address: usdtAddress, abi: TokenAbi, functionName: 'approve', args: [PresaleAddress, ETHweb3.utils.toBN(String(tokenAmount * (10 ** 6)))] })
          toast.success('Successfully approved');
        }
      }
      const approveData = await waitForTransaction({
        hash: approve.hash
      })
      console.log('approveData', approveData)
      setConfirming(false);
      let allowance;
      if (selectedToken === 1) {
        allowance = await readContract({ address: usdcAddress, abi: TokenAbi, functionName: 'allowance', args: [address, PresaleAddress] });
      } else if (selectedToken === 2) {
        allowance = await readContract({ address: usdtAddress, abi: TokenAbi, functionName: 'allowance', args: [address, PresaleAddress] });
      }
      setAllowanceAmount(Number(allowance) / (10 ** 6));
    } catch (err) {
      toast.error(err.details);
      setConfirming(false);
    }
  };

  const onTokenDeposit = async () => {
    try {
      setConfirming(true);
      let deposit;
      if (selectedToken === 0) {
        deposit = await writeContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'buyWithEth', value: ETHweb3.utils.toWei(String(tokenAmount), 'ether') })
      } else if (selectedToken === 1) {
        deposit = await writeContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'buyWithUSDC', args: [ETHweb3.utils.toBN(String(tokenAmount * (10 ** 6)))] })
      } else {
        deposit = await writeContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'buyWithUSDT', args: [ETHweb3.utils.toBN(String(tokenAmount * (10 ** 6)))] })
      }
      const depositData = await waitForTransaction({
        hash: deposit.hash
      })
      console.log('depositData', depositData)
      setTimeout(function () {
        setConfirming(false);
      }, 3000)
      toast.success(`Successfully ${tokenAmount} ${selectedToken === 0 ? 'ETH' : selectedToken === 1 ? 'USDC' : 'USDT'} deposited`);
      const presaleData = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'presale', args: [presaleId] });
      const Amount = Number(presaleData[7])
      setRaisedAmount(Amount);
      const percent = (Number(raisedAmount)) * 100 / totalAmount;
      const prog = percent.toFixed(3) + '%';
      setProgress(prog);
    } catch (err) {
      toast.error('There is a problem with your deposit. Please try again later');
      setConfirming(false);
    }
  };

  const onTokenClaim = async () => {
    try {
      setConfirming(true);
      let claim;
      claim = await writeContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'claimAmount', args: [presaleId] })
      const claimData = await waitForTransaction({
        hash: claim.hash
      })
      console.log('claimData', claimData)
      setTimeout(function () {
        setConfirming(false);
      }, 3000)
      toast.success(`Successfully claimed`);
      const claimableData = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'userClaimData', args: [address, presaleId] });
      let claimableAmounts = ethers.utils.formatEther(claimableData[2] - claimableData[3] - claimableData[4]);
      setClaimableAmount(claimableAmounts);
    } catch (err) {
      toast.error('There is a problem with your claim. Please try again later');
      setConfirming(false);
    }
  };

  const setMaxAmount = async () => {
    if (selectedToken === 0 && accountBalance > 0)
      accountBalance = accountBalance - 0.01;
    setAmount(accountBalance);
  };



  const ETHSelect = async () => {
    try {
      setSelectToken(0)
    } catch (err) {
    }
  }

  const USDCSelect = async () => {
    try {
      setSelectToken(1)
    } catch (err) {
    }
  }

  const USDTSelect = async () => {
    try {
      setSelectToken(2)
    } catch (err) {
    }
  }

  useEffect(() => {
    const FetchData = async () => {
      try {
        if (address) {
          if (selectedToken === 0) {
            setSelectedTokenName("ETH");
            // eslint-disable-next-line react-hooks/exhaustive-deps
            accountBalance = await ETHweb3.eth.getBalance(address);
            accountBalance = ETHweb3.utils.fromWei(accountBalance, 'ether');
            setAccountBalance(accountBalance)
          } else if (selectedToken === 1) {
            setSelectedTokenName("USDC");
            const balance = await readContract({ address: usdcAddress, abi: TokenAbi, functionName: 'balanceOf', args: [address] });
            setAccountBalance(Number(balance) / (10 ** 6));
          } else {
            setSelectedTokenName("USDT");
            const balance = await readContract({ address: usdtAddress, abi: TokenAbi, functionName: 'balanceOf', args: [address] });
            setAccountBalance(Number(balance) / (10 ** 6));
          }
        }

      } catch (e) {
        console.error(e)
      }
    }

    const FetchGlobalData = async () => {
      try {
        const Id = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'presaleId' });
        setPresaleId(Id);
        const presaleData = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'presale', args: [Id] });
        const overalllRaised = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'overalllRaised' });
        setTotalRaisedAmount(Number(overalllRaised) / (10** 6));
        setRaisedAmount(Number(presaleData[7]));
        setTotalAmount(Number(presaleData[6]));
        setTokenSoldAmount(Number(presaleData[4]));
        setTotalTokensToSell(Number(presaleData[5]));
        setCurrentPrice(Number(presaleData[2]) / (10 ** 18));
        setNextPrice(Number(presaleData[3]) / (10 ** 18));
        setPresaleStatus(presaleData[8]);
        setClaimable(presaleData[9]);
        if (presaleData[9] === true) {
          const claimableData = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'userClaimData', args: [address, Id] });
          let claimableAmounts = ethers.utils.formatEther(claimableData[2] - claimableData[3] - claimableData[4]);
          setClaimableAmount(claimableAmounts);
        }
        FetchData();
      } catch (e) {
        console.error(e)
      }
    }
    if (presaleId == null) {
      FetchGlobalData();
    } else {
      FetchData();
    }

  }, [selectedToken, address])

  useEffect(() => {
    const fetchProgress = async () => {
      try {
        const percent = (Number(raisedAmount)) * 100 / totalAmount;
        console.log('debug->precent', percent)
        const prog = percent.toFixed(3) + '%';
        setProgress(prog);
      } catch (e) {
        console.error(e)
      }
    }
    if (Number(raisedAmount) > 0) {
      fetchProgress();
    }
  }, [raisedAmount, totalAmount])

  useEffect(() => {
    const FetchData = async () => {
      try {
        let balance;
        if (selectedToken === 0) {
          balance = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'ethToTokens', args: [presaleId, ETHweb3.utils.toWei(String(tokenAmount), 'ether')] });
        } else if (selectedToken === 1) {
          balance = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'usdtToTokens', args: [presaleId, ETHweb3.utils.toBN(String(tokenAmount * (10 ** 6)))] });
        } else {
          balance = await readContract({ address: PresaleAddress, abi: PresaleAbi, functionName: 'usdtToTokens', args: [presaleId, ETHweb3.utils.toBN(String(tokenAmount * (10 ** 6)))] });
        }
        setOutTokenAmount((Number(balance) / (10 ** 18)).toFixed(0))
      } catch (e) {
        console.error(e)
      }
    }
    if (tokenAmount > 0)
      FetchData();
  }, [ETHweb3.utils, presaleId, selectedToken, tokenAmount])

  useEffect(() => {
    const useTokenCheck = async () => {
      try {
        let allowance;
        if (selectedToken === 1) {
          allowance = await readContract({ address: usdcAddress, abi: TokenAbi, functionName: 'allowance', args: [address, PresaleAddress] });
        } else if (selectedToken === 2) {
          allowance = await readContract({ address: usdtAddress, abi: TokenAbi, functionName: 'allowance', args: [address, PresaleAddress] });
        }
        setAllowanceAmount(Number(allowance) / (10 ** 6));
      } catch (e) {
        console.error(e)
      }
    }
    if (tokenAmount > 0) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useTokenCheck();
    }
  }, [address, selectedToken, tokenAmount])

  return (
    <main>
      <div className="GlobalContainer">
        {
          <div className="MainDashboard">
            <section className="ContactBox">
              <>
                <section className="ContractContainer">
                  <img src={R0ARHeader} alt="logo" className='tokenHeader' />
                  <section className={styles.ButtonContainer}>
                    <div className="connectButtonBox">
                      {!isConnected ?
                        <>
                          <button className="ConnectButton" type="submit" onClick={() => {
                            onConnectWallet();
                          }}>Connect Wallet</button>
                        </>
                        :
                        <section className={styles.ConnectWalletSection}>
                          {chain?.id === 1 ?
                            <button
                              className="ConnectButton" type="submit"
                              onClick={() => onConnect()}
                            >
                              {address.slice(0, 4) + '...' + address.slice(-4)}
                            </button>
                            :
                            <button
                              className="ConnectButton" type="submit"
                              onClick={() => switchNetwork?.(1)}
                            >
                              {'Switch To ETH'}
                              {isLoading && pendingChainId === 1 && ' (switching)'}
                            </button>
                          }
                        </section>
                      }
                    </div>
                  </section>
                  <section className="DepositBoxHeader">
                    <p className="ContractContentTextTitle">PRESALE | LIVE | STAGE 6</p>
                    <p className='ContractContentTextTitle1'>TOTAL RAISED: &nbsp; $ {Number((Number(totalRaisedAmount)).toFixed(0)).toLocaleString()}</p>
                  </section>
                  <section className='HeaderContent'>
                    <p>Raised</p>
                    <p>Total Amount</p>
                  </section>
                  <section className='HeaderContent2'>
                    <p>$ {Number((Number(raisedAmount) / (10 ** 6)).toFixed(0)).toLocaleString()}</p>
                    <p>$ {Number((Number(totalAmount) / (10 ** 6)).toFixed(0)).toLocaleString()}</p>
                  </section>
                  <section className='HeaderContent3'>
                    <section className='HeaderContent4' style={{ width: progress }}></section>
                  </section>
                  <section className='HeaderContent5'>
                    <span>UNTIL SOLD OUT : {Number((Number(tokenSoldAmount) / (10 ** 18)).toFixed(0)).toLocaleString()} / {Number((Number(totalToknesToSell) / (10 ** 18)).toFixed(0)).toLocaleString()} R0AR</span>
                  </section>
                  {Number(claimableAmount) > 0 ?
                    <section className='HeaderContent5'>
                      <span>YOUR HOLD : {Number(claimableAmount).toLocaleString()} R0AR</span>
                    </section>
                    :
                    <></>
                  }
                  <section className='HeaderContent6'>
                    <section className='stageBox unstarted'>
                      <p>Stage 5</p>
                      <span className='HeaderContent7'>1 R0AR = $ 0.0075</span>
                    </section>
                    <section className='stageBox starting'>
                      <p>Stage 6</p>
                      <span className='HeaderContent7'>1 R0AR= $ 0.01</span>
                    </section>
                    <section className='stageBox unstarted'>
                      <p>Stage 7</p>
                      <span className='HeaderContent7'>1 R0AR= $ 0.025</span>
                    </section>
                  </section>
                  <p class="one_how">1 R0AR = $ 0.01</p>
                  {/* {Number(currentPrice) > 0 ? (1 / Number(currentPrice)).toFixed(3) : 0} */}
                  <section className='tokensButton'>
                    {selectedToken === 0 ?
                      <button className="bnbButton tokenButtons selected" onClick={ETHSelect}>
                        <img className='tokenImage' src={eth} />&nbsp;ETH
                      </button>
                      :
                      <button className="bnbButton tokenButtons" onClick={ETHSelect}>
                        <img className='tokenImage' src={eth} />&nbsp;ETH
                      </button>
                    }
                    {selectedToken === 1 ?
                      <button className="bnbButton tokenButtons selected" onClick={USDCSelect}>
                        <img className='tokenImage' src={usdc} />&nbsp;USDC
                      </button>
                      :
                      <button className="bnbButton tokenButtons" onClick={USDCSelect}>
                        <img className='tokenImage' src={usdc} />&nbsp;USDC
                      </button>
                    }
                    {selectedToken === 2 ?
                      <button className="bnbButton tokenButtons selected" onClick={USDTSelect}>
                        <img className='tokenImage' src={usdt} />&nbsp;USDT
                      </button>
                      :
                      <button className="bnbButton tokenButtons" onClick={USDTSelect}>
                        <img className='tokenImage' src={usdt} />&nbsp;USDT
                      </button>
                    }
                  </section>
                  <section className='BalancePart'>
                    {selectedTokenName} Balance : {Number(accountBalance) > 0.00001 ? Number(accountBalance).toFixed(3) : '0'}
                  </section>
                  <>
                    {presaleStatus === true ?
                      <><section className='InputBox'>
                        <section className='InputSection'>
                          <div className='LpBalance'>
                            <p className='Text1'>You Pay : </p>
                            <p onClick={setMaxAmount} className="MaxButton">Max</p>
                          </div>
                          <section className='inputPanel'>
                            <section className='inputPanelHeader'>
                              <Input
                                placeholder="Enter amount"
                                label=""
                                type="number"
                                changeValue={setAmount}
                                value={tokenAmount}
                              />
                              {selectedToken === 0 ?
                                <img className='tokenImage' src={eth} />
                                :
                                selectedToken === 1 ?
                                  <img className='tokenImage' src={usdc} />
                                  :
                                  <img className='tokenImage' src={usdt} />}

                            </section>
                          </section>
                        </section>
                        <section className='InputSection'>
                          <div className='LpBalance'>
                            <p className='Text1'>Amount Of Tokens </p>
                          </div>
                          <section className='inputPanel'>
                            <section className='inputPanelHeader'>
                              <input
                                placeholder="0"
                                label=""
                                type="number"
                                value={outTokenAmount}
                              />
                              <img className='tokenImage' src={R0AR} />
                            </section>
                          </section>
                        </section>
                      </section>
                        {Number(tokenAmount) > Number(allowanceAmount) && selectedToken !== 0 ?
                          <section className="LockBox">
                            {confirming === false ?
                              <>
                                <button disabled={false} onClick={onTokenAllowance} className="PresaleButton">Allow Tokens First</button>
                              </>
                              :
                              <>
                                <p className='Text1'>Approving</p>
                                <ClipLoader
                                  color={'#36d7b7'}
                                  loading={confirming}
                                  size={30}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </>
                            }
                          </section>
                          :
                          <>
                            {
                              <section className="LockBox">
                                {confirming === false ?
                                  <>
                                    {isConnected && chain?.id === 1 ? <>
                                      <button disabled={false} onClick={onTokenDeposit} className="PresaleButton">Deposit Token Now!</button>
                                      {
                                        claimable === true && claimableAmount > 0
                                          ?
                                          <button disabled={false} onClick={onTokenClaim} className="PresaleButton">Claim ({Number(claimableAmount).toFixed(3)} R0AR)</button>
                                          :
                                          <></>
                                      }</> :
                                      <></>}
                                  </>
                                  :
                                  <>
                                    <p className='Text1'>Confirming...</p>
                                    <ClipLoader
                                      color={'#36d7b7'}
                                      loading={confirming}
                                      size={30}
                                      aria-label="Loading Spinner"
                                      data-testid="loader"
                                    />
                                  </>
                                }
                                {!isConnected ?
                                  <>
                                    <button className="PresaleButton" type="submit" onClick={() => {
                                      onConnectWallet();
                                    }}>Connect Wallet</button>
                                  </>
                                  :
                                  <>
                                    {chain?.id === 1 ?
                                      <></>
                                      :
                                      <button
                                        className="PresaleButton" type="submit"
                                        onClick={() => switchNetwork?.(1)}
                                      >
                                        {'Switch To ETH'}
                                        {isLoading && pendingChainId === 1 && ' (switching)'}
                                      </button>
                                    }
                                  </>
                                }
                              </section>
                            }
                          </>
                        }
                      </>
                      :
                      presaleStatus === false ?
                        <>
                          <p class="ContractContentTextTitle">Presale is not live yet</p>
                        </>
                        :
                        <>
                          <p className='Text1'>Data Loading...</p>
                          <ClipLoader
                            color={'#36d7b7'}
                            loading={confirming}
                            size={30}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </>
                    }
                  </>
                </section>
              </>
            </section>
          </div>
        }
      </div>
    </main >
  )
}

export default App
